import baseDomain, { courseAssets, nutriDiet } from '../../assets/images/imageAsset'

export const testimonialData = [
  {
    name: 'Jacqueline Mulliert',
    info: 'Student from Mexico',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/landing-page/testimonial1.png',
    message:
      'I can say that the 2 Months Online Teacher Training Course is just mind blowing! I`m Mexican and here, when we think of yoga, 98% of people will just picture postures that will make you look like a beautiful “pretzel” or someone in a meditative posture “Being Zen”. After much research, I finally found this Institute, which definitely changed my life for good and I am always going to be grateful for it. Because here, I could finally study yoga, to understand this amazing way of life, the root and essence of it. What is so valuable is that for every technique, asanas, kriya, pranayama, meditation, etc., teachers explained limitations and contraindications first. What I experience in my country or even online are so many studios that just teach you the postures and refer to it as a workout and not even talk about the limitations or what yoga means. For me, every session was a mind-blowing moment of knowledge. Every teacher is so amazing, happy and smiling. They teach such difficult knowledge like Yoga Sutras in a very simple way, and the way they prepare you in Practice Teaching classes to become a teacher is amazing; for me, each one of them is my guru. I am definitely coming back to do the advanced course.',
  },
  {
    name: 'José Manuel González Márquez',
    info: 'Model at Francina Models',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/landing-page/testimonial2.png',
    message:
      'I was interested in Yoga since a long time. I was practicing it in Spain, China & other places however it was in The Yoga Institute that I learnt what real Yoga is. I discovered that Yoga is not just a set of gymnastic stretches but comprises philosophy & study of mind. I started coming just for some weekend classes, then gave a try to 21 Days Better Living Course, which I loved and I really recommend it. Later i did my Teachers Training Course and now I’m a Yoga instructor. Also the Institute is QCI certified. The Sattvic food offered at The Yoga Institute is delicious. I think Yoga can have impact on our lives and The Yoga Institute is one of the best places in the world to discover it.',
  },
  {
    name: 'Nikita Wadhwa',
    info: 'Sadhaka',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/landing-page/testimonial3.png',
    message:
      'My journey with The Yoga Institute started at the beginning of this year where I found myself part of the TTC, training as a yoga teacher. The Institute has shown me that yoga is more than asanas and how to incorporate the yogic lifestyle in our daily lives. On this journey, it has united me with a community of like-minded people, who are filled with love and kindness for one and another, and I’m so happy to be part of it. Recently I got a chance to attend the 7-Day Health Camp and it felt like pure joy and peace. I am so grateful to the teachers for sharing their knowledge and for guiding us through this path. For me, The Yoga Institute is and will always be a home of bliss.',
  },
  {
    name: 'Malvika Krishnamurthy',
    info: 'Student from Canada',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/landing-page/testimonial4.png',
    message:
      'Completing my yoga teacher’s training at The Yoga Institute has made such a positive impact on my life. Alongside understanding the physical aspects of yoga, we learned subtle principles on how to live our lives. My current routines have also been changed by the newly implemented self-study practices I was introduced to in the course. I am so grateful for the journey I’ve had with TYI and I’m very excited to continue further with my own practice.',
  },
]

export const testimonialData2 = [
  {
    name: 'PRAKASH SHARMA, SADHAKA',
    info: '',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Landing_page/1.png',
    message:
    'I came to know about The Yoga Institute as my wife did one year TTC in 2012. She encouraged me to do 21 days better living course, and I enjoyed it to the fullest. In the same year my slip disc and asthma got cured with regular practice of 45 minutes of Yoga and 30 minutes walk helped me in keeping my mind and body healthy. Wise lessons of Hansaji and positive vibration from The Yoga Institute & its team has enriched my life. I’m really blessed to be a part of an institute that is 100 years old.',
  },
  {
    name: 'MAJOR V SREEDHARAN (RETD)',
    info: '',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Landing_page/2.png',
    message:
    'It is a great privilege to be a part of The Yoga Institute. I was lucky enough to begin with a 7 Day Health Camp in 2014 then 1 month TTC & 21 days health camp. My quest to better myself didn’t end there until I underwent a 3 Months Advanced Teachers’ Training Course. For all the above programmes, I have travelled from Hyderabad to Mumbai. The serenity at the Institute complex, the faculty for various subjects, the delicious food provided and the hostel accommodation are highly commendable, especially in Mumbai. Above all, the Director of the Institute, Dr. Hansaji, & the staff under her, are so helpful at all times. The Institute has progressed so much with regard to its infrastructure in the last few years, catering to the needs of one and all. The Oldest Organised Yoga Centre in the World ensures, “The light of Yoga once lit will never dim.” For an Army Officer like me, it is a wonderful experience.',
  },
  {
    name: 'Amar Patil, Police Inspector',
    info: '',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Landing_page/3.png',
    message:
    'In 2012, I was at Vakola Police Station. That time, Vice President Shri Venkaih Naidu had come and I was introduced to Hrishi Sir and The Yoga Institute. Though it was my dream to join the Institute and do a course, I could not due to time constraints. When online courses started recently, I joined the 21-Days course. It teaches discipline, concentration, diet control and many other useful topics. The teachers also taught us how to be positive and useful - I feel all the negativity is over now. I am very thankful to The Yoga Institute, especially Hansaji, Hrishi Sir, Monica Ma\'am, Meena Ma\'am and Abhay Sir.',
  }
]

export const testimonialData3 = [
  {
    name: 'Shvetika Kaul',
    info: '',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Nutri-Clinic/Shvetika%20Kaul.jpg',
    message:
    'I had gained weight and was feeling stuck for a very long time. I decided to take the weight loss diet program with The Yoga Institute’s Nutri Diet Clinic last month. The nutritionist guided me on the importance of food, when and how to consume. I was introduced to the varieties of millet, vegetables, lentils, spices, seeds and nuts. The first week itself made me feel light, energetic and gave me a feeling of happiness. The diet plan with easy to make recipes had all the varieties along with nutrients. I found myself thoroughly enjoying the process of eating food with the changes brought about in my diet. I look forward to each day with enthusiasm in my weight loss journey through Nutri Diet Clinic’s weight loss program.',
  },
  {
    name: 'Pallavi Raj',
    info: '',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Nutri-Clinic/Pallavi.jpg',
    message: 'I am a big fan of The Yoga Institute and all its courses. Nutri Diet Clinic is yet another beneficial program from the institute. The appreciation for the institute got me to enrol for their 1-month plan. But the guidance, proactive support, the effort taken by my Nutritionist to develop positive attitude for food when I was unable to follow the diet for the day is responsible for me to extend it to their 6-months plan. I was provided with customized diet plan which made eating healthy food very easy. I always found following diet difficult but not when healthy food is so delicious at the same time. I thank my nutritionist and gratitude to the institute for taking this initiative.'
  },
  {
    name: 'Aditi kaku',
    info: '',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Nutri-Clinic/Aditi-kaku.jpg',
    message:
    'I was looking for some good intermittent fasting options and TYI’s Nutri Diet Clinic was able to cater to my needs. I was provided with a customized plan based on my needs and preferences and healthy recipes were shared with me. It definitely changed my eating habits and I was able to lose some inches and weight as well. All my doubts were cleared with utmost patience, dedication by the team of the Nutri Diet Clinic.',
  }
]

export const testimonialData4 = [
  {
    name: 'Brianne Vaillancourt',
    info: 'Student from Canada',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/seven-days/Brianne.png',
    message: 'For five years, I was in search of a Yoga centre that offers a Teacher Training Course and then finally, I found The Yoga Institute. I came to this place without any expectations and in return, I received a rich and holistic learning experience from the TTC and 7-days health camp. The Yoga Institute has a warm and welcoming atmosphere. I recommend the institute, its 7-days health camp and teacher training course to anyone who is looking for an authentic Yogic experience.'
  },
  {
    name: 'Parineeta Gupta',
    info: 'Sadhaka',
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/seven-days/Parineeta.png',
    message:'The 7-day health camp was a much-needed retreat and yet it felt like a home away from home. It was great to soak in the positivity of the ambience at The Yoga Institute. For me, it brought back memories of the camp I attended 20 years ago in the presence of respected Dr. Jayadeva. The Asana classes at the beginning and end of the day were energizing and rejuvenating. The Karma Yoga sessions and learning Yogic concepts through games & enactment were enriching. The music, dance and laughter sessions towards the evening helped to release stress and experience a sense of freedom. Hansaji’s talks about Yoga and interesting stories reflected its practical application and integration into everyday life. All the teachers were very approachable and the volunteers were helpful. All in all, the 7-day health camp was Yoga-in-practice - it kept us joyfully, positively and actively engaged throughout. I shall always cherish the experience.',
  },
]

export const popularCourses = [
  {
    img: `${baseDomain}${courseAssets.courseAsset40}`,
    text: 'Advance TTC (900 Hrs)',
    url: '/3-months-advanced-teacher-training-course',
  },
  {
    img: `${baseDomain}${courseAssets.courseAsset34}`,
    text: '21 Days Better Living Course',
    url: '/21-days-better-living-course',
  },
  {
    img: `${baseDomain}${courseAssets.courseAsset34}`,
    url: '/7-days-camp-english',
    text: '7 Days Health Camp',
  },
  {
    img: `${baseDomain}${courseAssets.courseAsset75}`,
    url: '/certificate-yoga-therapy-course-online',
    text: 'Certificate Yoga Therapy Course',
  },
  {
    img: `${baseDomain}${nutriDiet.nutriDietMain}`,
    url: '/nutri-diet',
    text: 'Nutri Diet Clinic',
  },
]

export const CampsWorkShops = [
  {
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Courses/Camps/Stress_Management_Camp.jpeg',
    text: 'Stress Management Camp',
    url: '/stress-management-camp',
  },
  {
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Courses/Camps/Weight_Management_Workshop.jpeg',
    text: 'Weight Management Workshop',
    url: '/weight-management-workshop',
  },
  {
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Courses/Camps/Diabetes_Camp.jpeg',
    url: '/diabetes-camp',
    text: 'Diabetes Camp',
  },
  {
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Courses/Camps/Cardiac_Hypertension_Workshop.jpeg',
    url: '/cardiac-hypertension-workshop',
    text: 'Cardiac & Hypertension Workshop',
  },
  {
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Courses/Camps/Back_Joint_Disorder_Workshop.jpeg',
    url: '/back-joint-disorder-workshop',
    text: 'Back & Joint Disorder Workshop',
  },
]

export const landingFeaturedBlogs =[
  {
    img:'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Landing_page/1ttc.jpg',
    text:'One Month Yoga Teachers Training Course – Yoga Instructor Training at TYI',
    url:'/one-month-yoga-teachers-training-course-yoga-instructor-training'
  },
  {
    img:'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Landing_page/impyoga.jpg',
    text:'Importance and Benefits of Yoga',
    url:'/importance-and-benefits-of-yoga'
  }
]

export const landingFeaturedBlogs2 =[
  {
    img:'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/uploads/2022/07/21-Days-Better-Living-Course.jpeg',
    text:'New to Yoga? Take this 21 Day Challenge',
    url:'/new-to-yoga-twenty-one-days-challenge'
  },
  {
    img:'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/uploads/2020/08/yoga-preparation-tyi-blog.jpg',
    text:'How do I prepare for my Yoga class',
    url:'/how-do-i-prepare-for-my-yoga-class'
  }
]

export const NutriClinicBlogs =[
  {
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Nutri-Clinic/nutri1.jpg',
    text:'Why  should i adopt a sattvik diet',
    url:'/why-should-i-adopt-a-sattvik-diet'
  },
  {
    img:'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Nutri-Clinic/nutri2.jpg',
    text:'Super foods and its importance in our diet',
    url:'/super-foods-and-its-importance-in-our-diet'
  }
]

export const SevenBlogs =[
  {
    img: 'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/seven-days/importance-and-benefits-of-yoga.jpg',
    text:'Importance and benefits of yoga',
    url:'/importance-and-benefits-of-yoga'
  },
  {
    img:'https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/seven-days/Yoga-Dos-and-Donts-for-Beginners.jpg',
    text:'Yoga Dos and Donts for Beginners',
    url:'/yoga-asanas-dos-and-donts-for-beginners'
  }
]

